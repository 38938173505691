var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-row mb-2"},[_c('p',{staticClass:"text-2xl mb-2"},[_vm._v("Liste des Univers")]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'admin-edit-group-site' }}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.icons.mdiPencilBox))]),_vm._v("Ajouter un univers")],1)],1)],1),_c('v-card',[_c('v-card-title',{staticClass:"align-start"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche... ","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.options.total,"loading":_vm.loading,"sort-by":'ca',"sort-desc":true,"search":_vm.search,"footer-props":{
          'items-per-page-options': [10, 25, 50],
        },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openDialogSiteGroupView(item.id)}}},[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"target":"_blank"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"to":("/admin/edit-group-site/" + (item.id)),"elevation":"0","fab":"","small":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"elevation":"0","fab":"","small":""},nativeOn:{"click":function($event){_vm.dialogModel.dialog = !_vm.dialogModel.dialog;
                _vm.dialogModel.id = item.id;
                _vm.dialogModel.name = item.name;}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.dialogSiteGroupViewVisible),callback:function ($$v) {_vm.dialogSiteGroupViewVisible=$$v},expression:"dialogSiteGroupViewVisible"}},[_c('site-group-modal-view',{attrs:{"siteGroupId":_vm.siteGroupId,"sites":_vm.siteList}})],1),_c('v-dialog',{attrs:{"width":"70vw"},model:{value:(_vm.dialogModel.dialog),callback:function ($$v) {_vm.$set(_vm.dialogModel, "dialog", $$v)},expression:"dialogModel.dialog"}},[_c('site-group-del-confirmation',{attrs:{"dialogOpen":_vm.dialogModel.dialog,"id":_vm.dialogModel.id,"name":_vm.dialogModel.name},on:{"openDialog":_vm.openDialog}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }