<template>
  <div>
    <div class="d-flex flex-row mb-2">
      <p class="text-2xl mb-2">Liste des Univers</p>
      <v-col class="text-right">
        <v-btn color="primary" :to="{ name: 'admin-edit-group-site' }">
          <v-icon class="mr-3">{{ icons.mdiPencilBox }}</v-icon
          >Ajouter un univers</v-btn
        >
      </v-col>
    </div>
    <v-card>
      <v-card-title class="align-start">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche... "
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :page="options.page"
          :pageCount="numberOfPages"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="options.total"
          :loading="loading"
          :sort-by="'ca'"
          :sort-desc="true"
          class="elevation-1"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          :items-per-page="options.itemsPerPage"
        >
          <template v-slot:item.name="{ item }">
            <a @click="openDialogSiteGroupView(item.id)">
              <strong>{{ item.name }}</strong>
            </a>
          </template>
          <template v-slot:item.actions="{ item }">
            <a target="_blank">
              <v-btn
                :to="`/admin/edit-group-site/${item.id}`"
                class="mx-2"
                elevation="0"
                fab
                small
              >
                <v-icon color="primary">
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                elevation="0"
                fab
                small
                @click.native="
                  dialogModel.dialog = !dialogModel.dialog;
                  dialogModel.id = item.id;
                  dialogModel.name = item.name;
                "
              >
                <v-icon color="secondary">
                  {{ icons.mdiTrashCan }}
                </v-icon>
              </v-btn>
            </a>
          </template>
        </v-data-table>
      </v-card-text>
      <v-dialog v-model="dialogSiteGroupViewVisible" max-width="1000">
        <site-group-modal-view
          :siteGroupId="siteGroupId"
          :sites="siteList"
        ></site-group-modal-view>
      </v-dialog>
      <v-dialog v-model="dialogModel.dialog" width="70vw">
        <site-group-del-confirmation
          :dialogOpen="dialogModel.dialog"
          :id="dialogModel.id"
          :name="dialogModel.name"
          @openDialog="openDialog"
        ></site-group-del-confirmation>
      </v-dialog>
    </v-card>
  </div>
</template>
<style scoped></style>
<script>
import SiteGroupDelConfirmation from "@/components/admin/site-group/modals/DelConfirmation";
import SiteGroupModalView from "@/components/admin/site-group/modals/View";
import axios from "@axios";
import { mdiPencil, mdiTrashCan } from "@mdi/js";

export default {
  name: "SiteGroupList",
  components: { SiteGroupDelConfirmation, SiteGroupModalView },
  data() {
    return {
      siteList: [],
      search: "",
      numberOfPages: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      headers: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Univers",
          value: "name",
        },
        {
          text: "Code",
          value: "code",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      siteGroupId: null,
      dialogSiteGroupViewVisible: false,
      dialogModel: {
        dialog: false,
        id: -1,
        name: "",
      },
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
    };
  },
  watch: {
    // options: {handler(){this.updateData();}, deep:true,},
    // individual properties to avoid refreshing when options.total updates
    "options.itemsPerPage"() {
      this.updateData();
    },
    "options.page"() {
      this.updateData();
    },
    "options.sortBy"() {
      this.updateData();
    },
    "options.sortDesc"() {
      this.updateData();
    },
    search() {
      this.options.page = 1;
      this.updateData();
    },
  },
  methods: {
    openDialogSiteGroupView(id) {
      this.siteGroupId = id;
      this.dialogSiteGroupViewVisible = true;
    },
    async updateData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        page: page - 1,
        limit: itemsPerPage,
        ...(this.search ? { search: this.search } : {}),
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      };

      const { data } = await axios.get("/site-groups/", {
        params: queryParams,
      });
      this.loading = false;
      this.items = data.items;
      this.options.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
    openDialog(value) {
      this.dialogModel.dialog = value;
      this.updateData();
    },
  },
  async mounted() {
    this.updateData();
    this.siteList = await this.$store.dispatch("common/getSiteList");
  },
};
</script>
